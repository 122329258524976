/**
 * @generated SignedSource<<ca7e4d399ee5d4e98b4d68ead4974c3b>>
 * @relayHash fcb31ad4452c04c2e896944c9b846d64
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fcb31ad4452c04c2e896944c9b846d64

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type currentUserProviderQuery$variables = Record<PropertyKey, never>;
export type currentUserProviderQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"currentUserProvider_currentUser">;
};
export type currentUserProviderQuery = {
  response: currentUserProviderQuery$data;
  variables: currentUserProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryEmail",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "currentUserProviderQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "currentUserProvider_currentUser"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "currentUserProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkedInUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NetworkRoot",
            "kind": "LinkedField",
            "name": "defaultNetwork",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "impersonatedBy",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "fcb31ad4452c04c2e896944c9b846d64",
    "metadata": {},
    "name": "currentUserProviderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a31e674cf8ea17421db9e6e1818f9427";

export default node;
